import React from 'react';
import ReactDOM from 'react-dom/client'; // นำเข้า react-dom/client แทน react-dom
import App from './App';

// สร้าง root
const root = ReactDOM.createRoot(document.getElementById('root'));

// ใช้ root.render แทน ReactDOM.render
root.render(
  <App />
);
