import React, { useState, useEffect } from 'react';
import { UilPlusCircle, UilTimesCircle, UilCheckCircle } from '@iconscout/react-unicons';
import bcrypt from 'bcryptjs';
import './Amindash.css';
import Notification from '../Checklotto/Notification'; // นำเข้า Notification component

const Amindash = () => {
  const [users, setUsers] = useState([]);
  const [newUser, setNewUser] = useState({ username: '', email: '', password: '', status: '' });
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const port = process.env.REACT_APP_PORT;

  // State สำหรับควบคุมการแสดงผล Notification
  const [notification, setNotification] = useState({ show: false, message: '' });

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await fetch(`${baseUrl}:${port}/api/users`);
        const data = await response.json();
        setUsers(data);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };
    fetchUsers();
  }, []);

  const handleChange = (e, id) => {
    const { name, value } = e.target;
    setUsers((prevUsers) =>
      prevUsers.map((user) =>
        user.id === id ? { ...user, [name]: value } : user
      )
    );
  };

  const handleNewUserChange = (e) => {
    const { name, value } = e.target;
    setNewUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));
  };

  const handleSubmit = async (id) => {
    const updatedUser = users.find((user) => user.id === id);

    // สร้าง object ที่ประกอบไปด้วยแค่ username และ email
    const updatedUserData = {
      username: updatedUser.username,
      email: updatedUser.email,
      status: updatedUser.status
    };

    try {
      console.log('Sending data to API:', updatedUserData); // ตรวจสอบข้อมูลที่ส่ง
      const response = await fetch(`${baseUrl}:${port}/api/update-user/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedUserData),  // ส่งแค่ username และ email
      });

      console.log('API Response:', response); // ตรวจสอบการตอบสนองจาก API

      if (!response.ok) throw new Error('Failed to update user');

      console.log('User updated successfully');
      setNotification({ show: true, message: 'แก้ไขสำเร็จ!' });

    } catch (error) {
      console.error('Error updating user:', error);
      setNotification({ show: true, message: 'แก้ไขไม่สำเร็จ!' }); // แจ้งเตือนเมื่อบันทึกไม่สำเร็จ
    }
  };



  const handleAddNewUser = async () => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

    if (!emailRegex.test(newUser.email)) {
      setNotification({ show: true, message: 'กรุณากรอกอีเมลที่ถูกต้อง' });
      return;
    }

    const hashedPassword = bcrypt.hashSync(newUser.password, 10);
    const newUserWithHashedPassword = {
      ...newUser,
      password: hashedPassword
    };

    try {
      const response = await fetch(`${baseUrl}:${port}/api/add-user`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(newUserWithHashedPassword),
      });

      if (!response.ok) throw new Error('Failed to add new user');

      setNotification({ show: true, message: 'บันทึกข้อมูลสำเร็จ!' });
      setNewUser({ name: '', email: '', password: '', status: '' });

      const updatedUsersResponse = await fetch(`${baseUrl}:${port}/api/users`);
      const updatedUsers = await updatedUsersResponse.json();
      setUsers(updatedUsers);

    } catch (error) {
      console.error('Error adding new user:', error);
      setNotification({ show: true, message: 'Failed to add new user' });
    }
  };

  const handleDeleteUser = async (id) => {
    try {
      const response = await fetch(`${baseUrl}:${port}/api/delete-user/${id}`, {
        method: 'DELETE',
      });

      if (!response.ok) throw new Error('Failed to delete user');

      setUsers(users.filter(user => user.id !== id));  // ลบผู้ใช้จากสถานะ
      setNotification({ show: true, message: 'ลบสำเร็จ!' });  // แจ้งเตือนว่าลบสำเร็จ
    } catch (error) {
      console.error('Error deleting user:', error);
      setNotification({ show: true, message: 'ลบไม่สำเร็จ' });  // แจ้งเตือนว่าไม่สามารถลบได้
    }
  };

  return (
    <div className="user-management">
      <h1>User Management</h1>

      {/* Notification Component */}
      {notification.show && (
        <Notification
          message={notification.message}
          onClose={() => setNotification({ show: false, message: '' })}
        />
      )}

      {/* User Form */}
      <div className="user-form">
        <input
          type="text"
          name="name"
          placeholder="Name"
          value={newUser.name}
          onChange={handleNewUserChange}
        />
        <input
          type="email"
          name="email"
          placeholder="Email"
          value={newUser.email}
          onChange={handleNewUserChange}
        />
        <input
          type="password"
          name="password"
          placeholder="Password"
          value={newUser.password}
          onChange={handleNewUserChange}
        />
        <select
          name="status"
          value={newUser.status}
          onChange={handleNewUserChange}
        >
          <option value="">Status</option>
          <option value="Admin">Admin</option>
          <option value="User">User</option>
        </select>

        <button className="add-button" onClick={handleAddNewUser}>
          <UilPlusCircle size={20} />
        </button>
      </div>

      {/* User Table */}
      <table>
        <thead>
          <tr>
            <th>#</th>
            <th>Name</th>
            <th>Email</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user, index) => (
            <tr key={user.id}>
              <td>{index + 1}.</td>
              <td>
                <input
                  type="text"
                  name="username"
                  value={user.username}
                  onChange={(e) => handleChange(e, user.id)}
                />
              </td>
              <td>
                <input
                  type="email"
                  name="email"
                  value={user.email}
                  onChange={(e) => handleChange(e, user.id)}
                />
              </td>
              <td>
                <input
                  type="status"
                  name="status"
                  value={user.status}
                  onChange={(e) => handleChange(e, user.id)}
                />
              </td>
              <td>
                <div className="button-group">
                  <button
                    className="button-submit"
                    onClick={() => handleSubmit(user.id)}
                  >
                    <UilCheckCircle size={20} />
                  </button>
                  <button
                    className="delete-button"
                    onClick={() => handleDeleteUser(user.id)}
                  >
                    <UilTimesCircle size={20} />
                  </button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Amindash;
