import React, { useEffect, useState } from 'react';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './OnestPrize.css'; // ปรับตามชื่อไฟล์ CSS ของคุณ
import Notification from '../Checklotto/Notification';

const OnestPrize = () => {
  const [positionStatistics, setPositionStatistics] = useState([]);
  const [newLottoNumbers, setNewLottoNumbers] = useState([]); // สถานะสำหรับเลขใหม่
  const [currentPage, setCurrentPage] = useState(1); // สถานะสำหรับหน้า
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selected, setSelected] = useState({}); // สถานะสำหรับเลขที่ถูกเลือก
  const [displayedNumbers, setDisplayedNumbers] = useState([]); // สำหรับแสดงเลขที่สร้าง
  const [loadingGenerate, setLoadingGenerate] = useState(false); // เพิ่ม loading สำหรับการสร้างเลข
  const [generatedCount, setGeneratedCount] = useState(0); // สถานะสำหรับเก็บจำนวนเลขที่สร้าง
  const [isBuilding, setIsBuilding] = useState(true); // สถานะของปุ่ม
  const [selectedDate, setSelectedDate] = useState(new Date()); // สถานะสำหรับเก็บวันที่ที่เลือก
  const [statusMessage, setStatusMessage] = useState('');
  const [isNotificationOpen, setIsNotificationOpen] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState('');
  const [selectedQuantity, setSelectedQuantity] = useState(0); // ค่าเริ่มต้นเป็น 10
  const [newPairedNumbers, setNewPairedNumbers] = useState([]);

  const baseUrl = process.env.REACT_APP_BASE_URL;
  const port = process.env.REACT_APP_PORT;

  const itemsPerPage = 50; // จำนวน item ที่แสดงต่อหน้า

  const fetchStatus = async () => {
    try {
      const response = await axios.get(`${baseUrl}:${port}/api/botstatus`);
      setStatusMessage(response.data.message); // เก็บข้อมูลที่ได้รับในตัวแปรสถานะ
      console.log(response.data)
    } catch (error) {
      console.error('Error fetching data:', error); // แสดงข้อผิดพลาด
    }
  };

  // เรียก fetchStatus หนึ่งครั้งเมื่อ component โหลด
  useEffect(() => {
    fetchStatus();
  }, []);

  // ใช้ useEffect เพื่อตรวจสอบการเปลี่ยนแปลงของ statusMessage
  useEffect(() => {
    if (statusMessage) {
      console.log("Status message has changed:", statusMessage);
    }
  }, [statusMessage]); // รันทุกครั้งที่ statusMessage เปลี่ยนแปลง




  useEffect(() => {
    // กำหนดวันที่ดีฟอลต์
    const today = new Date();
    const currentDay = today.getDate();

    let defaultDate;
    if (currentDay > 1 && currentDay <= 16) {
      // ถ้าวันนี้มากกว่า 1 แต่ไม่เกิน 15 ให้แสดงวันที่ 16
      defaultDate = new Date(today.getFullYear(), today.getMonth(), 16);
    } else if (currentDay > 16) {
      // ถ้าวันนี้มากกว่า 16 ให้แสดงวันที่ 1 ของเดือนถัดไป
      defaultDate = new Date(today.getFullYear(), today.getMonth() + 1, 1);
    } else {
      // ถ้าวันนี้คือวันที่ 1 หรือก่อนหน้านั้นให้แสดงวันที่ 1 ของเดือนนี้
      defaultDate = new Date(today.getFullYear(), today.getMonth(), 1);
    }

    setSelectedDate(defaultDate);
  }, []);


  useEffect(() => {
    const fetchPositionStatistics = async () => {
      try {
        const response = await axios.get(`${baseUrl}:${port}/api/lottosixnumber`);
        setPositionStatistics(response.data.positionStatistics);
        // setNewLottoNumbers(response.data.newLottoNumbers); // ดึง newLottoNumbers จาก API
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('เกิดข้อผิดพลาดในการดึงข้อมูล');
      } finally {
        setLoading(false);
      }
    };

    fetchPositionStatistics();
  }, []);



  // คำนวณเลขเริ่มต้นและเลขสิ้นสุดสำหรับหน้าปัจจุบัน
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentNumbers = newLottoNumbers.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(displayedNumbers.length / itemsPerPage);

  const handleCheckboxChange = (digit, position, count) => {
    setSelected((prev) => {
      const updatedSelection = {
        ...prev,
        [position]: {
          ...prev[position],
          [digit]: !prev[position]?.[digit], // สลับสถานะของ checkbox
        },
      };
  
      // สร้างออบเจ็กต์ selectedNumbers โดยเลือกตัวเลขที่ผู้ใช้เลือกในแต่ละตำแหน่ง
      const selectedNumbers = {};
      for (let pos = 1; pos <= 6; pos++) {
        selectedNumbers[pos] = Object.keys(updatedSelection[pos] || {})
          .filter((key) => updatedSelection[pos][key]) // เลือกเฉพาะตัวเลขที่ผู้ใช้เลือก
          .map((key) => key);
      }
  
      // เรียงตัวเลขตามจำนวน count จากมากไปหาน้อย
      const sortedNumbers = {};
      for (const pos in selectedNumbers) {
        sortedNumbers[pos] = selectedNumbers[pos]
          .map((digit) => {
            const countForDigit = positionStatistics.find(
              (data) => data.position === parseInt(pos)
            )?.digits.find((digitData) => digitData.digit === digit)?.count;
            return { digit, count: countForDigit };
          })
          .sort((a, b) => b.count - a.count); // เรียงจากมากไปหาน้อย
      }
  
      // เริ่มจับคู่เลขจากตำแหน่งที่เลือก
      const selectedPositions = Object.keys(sortedNumbers).filter(
        (pos) => sortedNumbers[pos]?.length > 0
      );
  
      const newPairedNumbers = [];
  
      const combinations = (current, depth) => {
        if (depth === selectedPositions.length) {
          newPairedNumbers.push(current.join(''));
          return;
        }
  
        const pos = selectedPositions[depth];
        const digits = sortedNumbers[pos].map((item) => item.digit); // เลขที่เลือกในตำแหน่งนี้
  
        for (const digit of digits) {
          combinations([...current, digit], depth + 1);
        }
      };
  
      if (selectedPositions.length > 0) {
        combinations([], 0);
      }
  
      // log ตัวเลขที่จับคู่ได้
      console.log('เลขที่จับคู่ได้:', newPairedNumbers);
  
      // อัปเดตจำนวนเลขที่ถูกสร้าง
      setGeneratedCount(newPairedNumbers.length);
      setSelectedQuantity(newPairedNumbers.length);
      setNewPairedNumbers(newPairedNumbers);
  
      return updatedSelection;
    });
  };
  


  const handleGenerateNumbers = () => {
    setIsBuilding(false); // เปลี่ยนสถานะปุ่มเป็น Submit

    // เรียกใช้ตัวเลขที่สร้างจาก handleCheckboxChange
    setDisplayedNumbers([...new Set(newPairedNumbers)]); // อัปเดตตัวเลขที่สร้างใน state

    setCurrentPage(1); // รีเซ็ตหน้าไปที่หน้าแรก
  };


  const handleSubmit = async () => {
    // ตรวจสอบสถานะว่าเป็น 'Bot disconnected'
    if (statusMessage === 'Bot disconnected') {
      // แสดงข้อความแจ้งเตือนเมื่อไม่ได้เชื่อมต่อ
      setNotificationMessage('ไม่สามารถส่งข้อมูลได้ เนื่องจากการเชื่อมต่อถูกตัด');
      setIsNotificationOpen(true);
      setIsBuilding(true);
      return; // หยุดการทำงานของฟังก์ชัน
    }
  
    try {
      // สร้างวันที่ใหม่จาก selectedDate
      const date = new Date(selectedDate);
  
      // เพิ่มวันเข้าไป 1 วัน
      date.setDate(date.getDate() + 1);
  
      // แปลงวันที่เป็น ISO string ในรูปแบบ YYYY-MM-DD
      const dateFormatted = date.toISOString().split('T')[0];
  
      // สร้าง array ที่มีจำนวนตามที่เลือก (ใช้ selectedQuantity)
      const numbersToSend = displayedNumbers.slice(0, selectedQuantity);
  
      console.log('Sending data:', { numbers: numbersToSend });
      const response = await fetch(`${baseUrl}:${port}/api/saveLottoNumbers`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          numbers: numbersToSend, // ส่งเฉพาะจำนวนที่เลือก
          date: dateFormatted, // ส่งวันที่ไปใน request
        }),
      });
  
      console.log('HTTP Status:', response.status); // แสดงสถานะ HTTP
  
      if (!response.ok) {
        const errorMessage = await response.text(); // อ่านข้อความผิดพลาดจากการตอบกลับ
        throw new Error(`Network response was not ok: ${errorMessage}`);
      }
  
      const data = await response.json();
      console.log('Response:', data);
  
      // ตั้งค่าข้อความแจ้งเตือนเมื่อส่งข้อมูลสำเร็จ
      setNotificationMessage('ส่งข้อมูลสำเร็จ!');
      setIsNotificationOpen(true);
      setIsBuilding(true); // แสดงสถานะกำลังประมวลผล
    } catch (error) {
      console.error('Error submitting data:', error);
  
      // ตั้งค่าข้อความแจ้งเตือนเมื่อมีข้อผิดพลาด
      setNotificationMessage(`ส่งข้อมูลไม่สำเร็จ!: ${error.message}`);
      setIsNotificationOpen(true);
    }
  };
  


  const handleCloseNotification = () => {
    setIsNotificationOpen(false);
  };


  // ฟังก์ชันสำหรับเปลี่ยนหน้า
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  // คำนวณเลขที่จะแสดงในหน้าปัจจุบัน
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentItems = displayedNumbers.slice(startIndex, endIndex);

  if (loading) {
    return (
      <div className="loading-container">
        <div className="loading-spinner"></div>
      </div>
    );
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="scrollable-container">
      <h1>รางวัลที่ 1</h1>
      <div className="position-grid">
        {positionStatistics.map((positionData) => (
          <div key={positionData.position} className="position-item">
            <h3>ตำแหน่งที่ {positionData.position}</h3>
            <ul>
              {positionData.digits.map((digitData) => (
                <li key={digitData.digit}>
                  เลข: {digitData.digit} จำนวน: {digitData.count} ครั้ง
                  <input
                    type="checkbox"
                    className="digit-checkbox"
                    onChange={() => handleCheckboxChange(digitData.digit, positionData.position, digitData.count)} // ฟังก์ชันสำหรับจัดการการเปลี่ยนแปลงเช็กบล็อก
                  />
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>


      <div className="quantity-container">
        <div className="quantity-label">
          จำนวนเลข : {generatedCount} ใบ
        </div>
        <div>
          <p className={
            statusMessage === 'Bot disconnected' ? 'disconnected' :
              statusMessage === 'Bot connected' ? 'connected' : ''
          }>
            {statusMessage || "Bot disconnected"}
          </p>
        </div>

        <div className="date-picker">
          <label>งวดวันที่:</label>
          <DatePicker
            selected={selectedDate}
            onChange={(date) => setSelectedDate(date)}
            dateFormat="dd/MM/yyyy"
            placeholderText="เลือกวันที่"
          />
        </div>
        <button className={isBuilding ? "build-button" : "submit-button"} onClick={isBuilding ? handleGenerateNumbers : handleSubmit}>
          {isBuilding ? 'Create' : 'Send data'}
        </button>
      </div>

      {isNotificationOpen && (
        <Notification message={notificationMessage} onClose={handleCloseNotification} />
      )}

      <div className="CardonestPrize">
        <div className="header">
          <h3>New Number</h3>
          {/* เพิ่มชื่อ 'จำนวนที่ต้องการส่ง' ข้างๆ */}
          <span className="amount-label">จำนวนที่ต้องการส่ง</span>

          {/* Dropdown สำหรับเลือกจำนวนระหว่าง 1 - 1000 */}
          <select
            id="quantity"
            value={selectedQuantity} // ค่าที่เลือกจะถูกเก็บใน state selectedQuantity
            onChange={(e) => setSelectedQuantity(Number(e.target.value))} // เมื่อเลือกจะอัปเดต selectedQuantity
            className="quantity-dropdown"
          >
            {[...Array(1000).keys()].map(i => (
              <option key={i + 1} value={i + 1}>
                {i + 1}
              </option>
            ))}
          </select>
        </div>

        {loadingGenerate ? (
          <div className="loading-container">
            <div className="loading-spinner"></div>
            <div className="loading-text">กำลังสร้าง...</div>
          </div>
        ) : (
          <div className="new-numbers-grid">
            {currentItems.length > 0 ? (
              currentItems.map((number, index) => (
                <div key={index} className="new-number-item">
                  {number}
                </div>
              ))
            ) : (
              <div className="no-numbers">ยังไม่มีเลขที่สร้าง</div>
            )}
          </div>
        )}

        <div className="pagination-controls">
          <button onClick={handlePrevPage} disabled={currentPage === 1}>
            ก่อนหน้า
          </button>
          <span>หน้า {currentPage} จาก {totalPages}</span>
          <button onClick={handleNextPage} disabled={currentPage >= totalPages}>
            ถัดไป
          </button>
        </div>
      </div>


    </div>
  );
};

export default OnestPrize;
