import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './Reporttable.css';

const Wrongcheck = () => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const port = process.env.REACT_APP_PORT;

    const [periods, setPeriods] = useState([]); // เก็บงวดทั้งหมด
    const [selectedPeriod, setSelectedPeriod] = useState(''); // เก็บงวดที่เลือก
    const [allReportData, setAllReportData] = useState([]); // เก็บข้อมูลทั้งหมด
    const [filteredReportData, setFilteredReportData] = useState([]); // เก็บข้อมูลที่กรอง

    const navigate = useNavigate();

    const handleCheckErrorClick = () => {
        navigate('/Reporttable');
    };

    const handleDetailsClick = () => {
        navigate('/Details');
    };

    useEffect(() => {
        // ดึงข้อมูลจาก API
        const fetchData = async () => {
            try {
                const response = await axios.get(`${baseUrl}:${port}/api/report`);
                const data = response.data;

                // ดึง period_no ทั้งหมด และเรียงจากล่าสุด
                const periodsSorted = [...new Set(data.map(item => item.period_no))].sort((a, b) => b.localeCompare(a));
                setPeriods(periodsSorted);
                setSelectedPeriod(periodsSorted[0]); // เลือกงวดล่าสุดโดยค่าเริ่มต้น

                // รวมข้อมูลผู้ใช้ที่ชื่อซ้ำในงวดเดียวกัน
                const groupedData = data.reduce((acc, curr) => {
                    const key = `${curr.period_no}-${curr.username}`;
                    if (!acc[key]) {
                        acc[key] = {
                            period_no: curr.period_no,
                            username: curr.username,
                            round1: 0,
                            round2: 0,
                            round3: 0,
                            total: 0,
                        };
                    }
                    if (curr.round === 1) acc[key].round1 += curr.fail_1; // ใช้เฉพาะ fail_1
                    if (curr.round === 2) acc[key].round2 += curr.fail_1;
                    // if (curr.round === 3) acc[key].round3 += curr.fail_1;
                    acc[key].total += curr.fail_1;
                    return acc;
                }, {});

                const reportDataArray = Object.values(groupedData);
                setAllReportData(reportDataArray);
                setFilteredReportData(reportDataArray.filter(item => item.period_no === periodsSorted[0])); // แสดงข้อมูลงวดล่าสุด
            } catch (error) {
                console.error('Error fetching report data:', error);
            }
        };

        fetchData();
    }, [baseUrl, port]);

    const handlePeriodChange = (period) => {
        setSelectedPeriod(period);
        const filteredData = allReportData.filter(item => item.period_no === period);
        setFilteredReportData(filteredData);
    };

    return (
        <div className="profile-container">

            <div className="profile-card">
                <div className="check-info">
                    <h1>ตารางตรวจผิด</h1>
                    <div className="buttongroup">
                        <button className="checkbutton" onClick={handleCheckErrorClick}>
                            ตรวจทั้งหมด
                        </button>
                        <button className="detailsbutton" onClick={handleDetailsClick}>
                            รายละเอียด
                        </button>
                        <div className="dropdown">
                            <button className="dropdown-button">{selectedPeriod}</button>
                            <div className="dropdown-content">
                                {periods.map((period, index) => (
                                    <a
                                        href="#!"
                                        key={index}
                                        onClick={() => handlePeriodChange(period)}
                                    >
                                        {period}
                                    </a>
                                ))}
                            </div>
                        </div>
                    </div>
                    <table className="styled-table">
                        <thead>
                            <tr>
                                <th>งวดที่</th>
                                <th>ผู้ตรวจ</th>
                                <th>รอบที่ 1</th>
                                <th>รอบที่ 2</th>
                                <th>รวม</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredReportData.map((item, index) => (
                                <tr key={index}>
                                    <td>{item.period_no}</td>
                                    <td>{item.username}</td>
                                    <td>{item.round1}</td>
                                    <td>{item.round2}</td>
                                    <td>{item.total}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default Wrongcheck;
