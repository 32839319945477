import React from 'react';
import { Navigate } from 'react-router-dom';

// PrivateRoute component
const PrivateRoute = ({ element, isAdminOnly }) => {
  const status = localStorage.getItem('status'); // ดึงชื่อผู้ใช้จาก localStorage

  // ถ้าผู้ใช้ไม่ได้ล็อกอิน
  if (!status) {
    return <Navigate to="/login" />;
  }

  // ถ้าผู้ใช้เป็น admin ให้เข้าได้ทุกหน้า
  if (status === 'Admin') {
    return element;
  }

  // ถ้าผู้ใช้ไม่ใช่ admin และพยายามเข้าถึงหน้าเฉพาะ
  if (isAdminOnly) {
    return <Navigate to="/profile" />; // หรือหน้าที่ผู้ใช้สามารถเข้าถึงได้
  }

  // ถ้าไม่ใช่ admin และไม่พยายามเข้าหน้าเฉพาะ จะให้เข้าหน้าได้
  return element;
};

export default PrivateRoute;
