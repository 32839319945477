import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './Reporttable.css';

const Details = () => {
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const port = process.env.REACT_APP_PORT;
    const navigate = useNavigate();
    const [fail1Results, setFail1Results] = useState([]);
    const [fail0Results, setFail0Results] = useState([]);

    // ดึงข้อมูลจาก API
    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`${baseUrl}:${port}/api/report/details`);
                // เซตข้อมูลที่ได้รับ
                setFail1Results(response.data.fail1Results);  // คาดว่า API จะส่งข้อมูลนี้
                setFail0Results(response.data.fail0Results);  // คาดว่า API จะส่งข้อมูลนี้
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        fetchData();
    }, [baseUrl, port]);

    const handleCheckErrorClick = () => {
        navigate('/Wrongcheck');
    };

    // ฟังก์ชันเพื่อค้นหา `lottoNumbers` จาก `fail0Results` ตาม `id_ref`
    const getCorrectLottoNumbers = (id_ref) => {
        const result = fail0Results.find(row => row.id_ref === id_ref);
        return result ? result.lottoNumbers : ''; // ถ้าไม่พบให้คืนค่าเป็นค่าว่าง
    };

    // ฟังก์ชันจัดรูปแบบตัวเลขในรูปแบบ xx-xxxxxx-xx-xx
    const formatLottoNumbers = (numbers) => {
        const cleanNumbers = numbers.replace(/[^0-9]/g, ''); // เอาเฉพาะตัวเลข
        return `${cleanNumbers.slice(0, 2)}-${cleanNumbers.slice(2, 8)}-${cleanNumbers.slice(8, 10)}-${cleanNumbers.slice(10, 12)}`;
    };

    // ฟังก์ชันสำหรับการแยกและเปรียบเทียบตัวเลขแต่ละตัว
    const compareLottoNumbers = (correct, error) => {
        const correctNumbers = correct.split(''); // แยกตัวเลขจาก Correct
        const errorNumbers = error.split(''); // แยกตัวเลขจาก Error
        const comparisonResult = [];

        for (let i = 0; i < correctNumbers.length; i++) {
            comparisonResult.push({
                correct: correctNumbers[i],
                error: errorNumbers[i],
                isError: correctNumbers[i] !== errorNumbers[i], // เปรียบเทียบตัวเลขตรงๆ
            });
        }
        return comparisonResult;
    };

    return (
        <div className="profile-container">
            <div className="profile-card">
                <div className="check-info">
                    <h1>รายละเอียด</h1>
                    <div className="buttongroup">
                        <button className="check-error-button" onClick={handleCheckErrorClick}>
                            ตรวจผิด
                        </button>

                        <div className="dropdown">
                            <button className="dropdown-button">งวดที่</button>
                            <div className="dropdown-content">
                                <a href="#option1">งวดที่ 1</a>
                                <a href="#option2">งวดที่ 2</a>
                                <a href="#option3">งวดที่ 3</a>
                            </div>
                        </div>
                    </div>

                    <table className="styled-table">
                        <thead>
                            <tr>
                                <th>งวดที่</th>
                                <th>OrderID</th>
                                <th>Correct</th>
                                <th>Error</th>
                                <th>User</th>
                            </tr>
                        </thead>
                        <tbody>
                            {fail1Results.map((row, index) => {
                                const correctLottoNumbers = getCorrectLottoNumbers(row.id_ref);
                                const formattedCorrectLotto = formatLottoNumbers(correctLottoNumbers);  // จัดรูปแบบตัวเลขใน Correct
                                const formattedErrorLotto = formatLottoNumbers(row.lottoNumbers);  // จัดรูปแบบตัวเลขใน Error
                                const comparisonResult = compareLottoNumbers(formattedCorrectLotto, formattedErrorLotto);

                                return (
                                    <tr key={index}>
                                        <td>{row.Period_no}</td>
                                        <td>{row.id_ref}</td>
                                        <td>
                                            {/* แสดงเลขใน Correct และเปลี่ยนสีเขียว */}
                                            {comparisonResult.map((item, idx) => (
                                                <span key={idx} className="correct-or">
                                                    {item.correct}
                                                </span>
                                            ))}
                                        </td>
                                        <td>
                                            {/* แสดงเลขใน Error และเปลี่ยนสีแดงถ้าผิด */}
                                            {comparisonResult.map((item, idx) => (
                                                <span key={idx} className={item.isError ? 'error-or' : ''}>
                                                    {item.error}
                                                </span>
                                            ))}
                                        </td>

                                        <td>{row.username}</td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default Details;
