import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import Image from "../../imgs/imge12.png"; // ภาพที่ใช้ในหน้า
import './Login.css';

const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState("");
    const navigate = useNavigate();
    const baseUrl = process.env.REACT_APP_BASE_URL;
    const port = process.env.REACT_APP_PORT;

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch(`${baseUrl}:${port}/api/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ email, password }),
            });

            const data = await response.json();

            console.log("Response data:", data);

            if (response.ok) {
                console.log(data.message);
                localStorage.setItem('username', data.user.username);
                localStorage.setItem('status', data.user.status); // บันทึกชื่อผู้ใช้ใน Local Storage
                console.log(localStorage.getItem('status'));
                navigate('/Reporttable'); // เปลี่ยนเส้นทางไปยังหน้า '/profile'

            } else {
                setError(data.message); // ตั้งค่าข้อความผิดพลาด
            }
        } catch (error) {
            console.error("Error:", error);
            setError("เกิดข้อผิดพลาดในการล็อกอิน");
        }
    };


    return (
        <div className="login-main">
            <div className="login-left">
                <img src={Image} alt="Login" />
            </div>
            <div className="login-right">
                <div className="login-right-container">
                    <h2>welcome !</h2>
                    <p>Please fill in your details.</p>
                    {error && <p className="error-message">{error}</p>}
                    <form onSubmit={handleSubmit}>
                        <input
                            type="email"
                            placeholder="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                            className="input-field"
                        />
                        <div className="pass-input-div">
                            <input
                                type={showPassword ? "text" : "password"}
                                placeholder="Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                                className="input-field"
                            />
                            <span onClick={() => setShowPassword(!showPassword)} className="show-password">
                                {showPassword ? "Hide" : "Show"}
                            </span>
                        </div>
                        <div className="login-center-buttons">
                            <button type="submit" className="login-button">Log In</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Login;
