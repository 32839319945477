import React from 'react';
import './Notification.css'; // สไตล์สำหรับการแจ้งเตือน

const Notification = ({ message, onClose }) => {
    return (
        <div className="notification-overlay">
            <div className="notification-box">
                <button className="notification-close" onClick={onClose}>× </button>
                <div className="notification-icon">🔔</div>
                <h2 className="notification-message">{message}</h2>
                <button className="notification-btn" onClick={onClose}>ปิด</button>
            </div>
        </div>
    );
};

export default Notification;
